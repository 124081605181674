exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-components-about-us-js": () => import("./../../../src/pages/about-us/components/about-us.js" /* webpackChunkName: "component---src-pages-about-us-components-about-us-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-all-tours-components-listings-js": () => import("./../../../src/pages/all-tours/components/listings.js" /* webpackChunkName: "component---src-pages-all-tours-components-listings-js" */),
  "component---src-pages-all-tours-index-js": () => import("./../../../src/pages/all-tours/index.js" /* webpackChunkName: "component---src-pages-all-tours-index-js" */),
  "component---src-pages-charter-tours-components-charter-tours-js": () => import("./../../../src/pages/charter-tours/components/charter-tours.js" /* webpackChunkName: "component---src-pages-charter-tours-components-charter-tours-js" */),
  "component---src-pages-charter-tours-index-js": () => import("./../../../src/pages/charter-tours/index.js" /* webpackChunkName: "component---src-pages-charter-tours-index-js" */),
  "component---src-pages-contact-us-components-contact-js": () => import("./../../../src/pages/contact-us/components/contact.js" /* webpackChunkName: "component---src-pages-contact-us-components-contact-js" */),
  "component---src-pages-contact-us-index-js": () => import("./../../../src/pages/contact-us/index.js" /* webpackChunkName: "component---src-pages-contact-us-index-js" */),
  "component---src-pages-gift-shop-components-gift-shop-js": () => import("./../../../src/pages/gift-shop/components/gift-shop.js" /* webpackChunkName: "component---src-pages-gift-shop-components-gift-shop-js" */),
  "component---src-pages-gift-shop-index-js": () => import("./../../../src/pages/gift-shop/index.js" /* webpackChunkName: "component---src-pages-gift-shop-index-js" */),
  "component---src-pages-home-components-about-js": () => import("./../../../src/pages/home/components/about.js" /* webpackChunkName: "component---src-pages-home-components-about-js" */),
  "component---src-pages-home-components-hero-main-js": () => import("./../../../src/pages/home/components/hero-main.js" /* webpackChunkName: "component---src-pages-home-components-hero-main-js" */),
  "component---src-pages-home-components-reviews-js": () => import("./../../../src/pages/home/components/reviews.js" /* webpackChunkName: "component---src-pages-home-components-reviews-js" */),
  "component---src-pages-home-components-services-js": () => import("./../../../src/pages/home/components/services.js" /* webpackChunkName: "component---src-pages-home-components-services-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-tours-components-featured-tours-js": () => import("./../../../src/pages/tours/components/featured-tours.js" /* webpackChunkName: "component---src-pages-tours-components-featured-tours-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-tourdetails-js": () => import("./../../../src/templates/tourdetails.js" /* webpackChunkName: "component---src-templates-tourdetails-js" */)
}

